import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormPage from '../partials/FormPage'
import EditTime from '../partials/EditTime'
import CheckboxPage from '../partials/CheckboxPage'
import ShareOptionsPage from '../partials/ShareOptionsPage'


import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

import sigtuna from '../resources/img/Sigtuna.png';
import osteraker from '../resources/img/Österåker.png';

import { ReactComponent as Question } from '../resources/img/question.svg';

import {connect} from 'react-redux'
import { addAllData, addInput, changeTimepoints, changeSelectedMunicipaly } from '../../actions/backendDataAction'
import { setTimepointTasks, setExtraTask, updateWeeksOff, updateShared, setCheckboxes, setAllTimepointTasks } from '../../actions/answerModelAction'

import { ReactComponent as Logo } from '../../img/plus-logo.svg';
import myData from '../resources/testData/testdata.json';

import { saveAs } from 'file-saver';

import apiConfig from "../resources/config/api-config.json";

//const URL = apiConfig.development;
const URL = apiConfig.live;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">   
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()} 
      {'.'}
    </Typography>
  );
}




const classes = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

class Client extends React.Component {

	state = {
		answerModel:{},
		activeSteps: [],
		timePerTimepoint:{},
		editTime: false,
		selectKey: "",
		showErrorModal: false,
		loading: true,
		calculationError: false,
	}

	componentWillMount(){
	
	    //this.props.addNames(myData);
	    this.getAllData();
	   
	}

	setExtraTasksd(){

		let answer = {}
	    const { selectedMunicipaly } = this.props.backendDataReducer;
	    const { extraTasks } = this.props.backendDataReducer.data[selectedMunicipaly];
	    extraTasks.map((extraTask, index) => {
	    	answer[extraTask.name] = {
	    		"frequency": extraTask.hasFrequency ? "-" : "Var fjärde vecka",
                "selection": extraTask.selections[0]
	    	}
	    })

	    this.props.setExtraTask(answer);
	}

	setInitialCheckboxes(){
		let answer = {}
	    const { selectedMunicipaly } = this.props.backendDataReducer;
	    const { checkboxes } = this.props.backendDataReducer.data[selectedMunicipaly];
	    Object.keys(checkboxes).map((checkbox) => {
	    	answer[checkbox] = false;
	    })

	    this.props.setCheckboxes(answer);
	}

	setInitialTimePoints(){
		
	    const { selectedMunicipaly } = this.props.backendDataReducer;
	    const { timepoints, timepointTasks } = this.props.backendDataReducer.data[selectedMunicipaly];
	    let result = {}
	    timepoints.map((timepoint) => {
			let answer = {}
	    	timepointTasks.map((timepointTask, index) => {
		    	answer[timepointTask.name] = {
		    		"frequency": 0,
	                "selection": timepointTask.selections[0]
			    	}
		    })
		    result[timepoint] = answer;
	    })

	    this.props.setAllTimepointTasks(result);

	}

	getAllData(){
		fetch(URL + "/inputForm?municipality=" + this.props.role, { 
			headers: new Headers({
			     'Authorization': 'Bearer ' + this.props.token,
			   })
			})
		  .then(res => res.json())
		  .then(
		    (result) => {
		      this.props.addInput(this.props.role, result);
		      this.props.changeSelectedMunicipaly(this.props.role);
		      this.setExtraTasksd();
			  this.setInitialCheckboxes()
			  this.setInitialTimePoints()
			  this.props.updateWeeksOff(0);
			  this.setState({ loading: false })
		    },
		    // Note: it's important to handle errors here
		    // instead of a catch() block so that we don't swallow
		    // exceptions from actual bugs in components.
		    (error) => {
		      console.log("error", error)
		    }
		 )
	}

	

	buildInput(input){
	    let obj = {}
	    input.timepoints.map((timepoint, index) => {
	    	if(this.state.activeSteps.includes(index)){
	    		let timeInputObj = {}
	        
		        input.timepointTasks.map((timepointTask) => {            
		            timeInputObj[timepointTask.name] = {frequency:0, selection:Object.keys(timepointTask.selectionDescriptions)[0]}
		        })
		        obj[timepoint] = timeInputObj
	    	}
	    })
	    let extraObj = {}
	    input.extraTasks.map((task) => {
	        extraObj[task.name] = ""
	    })
	    let checkboxObj = {}
	    input.extraTasks.map((task) => {
	        checkboxObj[task.name] = ""
	    })
	    return {
	        "weeksOff": input.weeksOff,
	    	"shared": input.shared,
	        timepointTasks: input.timepointTask,
	        extraTasks: extraObj,
	 		checkboxes: checkboxObj,
	    }
	}

	prepareForCalculation(){
		const data = this.props.answerModelReducer;
	}

	// new data = new timepoints
	changeTimepoints(newTimepoints){
		this.props.changeTimepoints(newTimepoints);
	}


	getHours = (num) => {
	    return Math.floor(num);
	}

	getMinutes = (num) => {
	    return Math.floor((num - this.getHours(num)) * 60);
	}

	createTimeString = (hours) => {
	    return "" + this.getHours(hours) + "h " + this.getMinutes(hours) + "min"
	}
	
	checkSelectionOkay(){
		// const { extraTasks } = this.props.answerModelReducer;
		// Object.keys(extraTasks).map((key) => {
		// 	if(extraTasks[key].frequency !== "-")
		// 	console.log("key", key)
		// 	extra = {
		// 		...extra, 
		// 		[key]: {
		// 			...extraTasks[key]	,
		// 			["frequency"]: converter[extraTasks[key].frequency],
		// 		}
		// 	}
		// })
	}

	stripNotSelected(data){
		let newTimepointsTasks = {};
		const timePointTasks = data.timepointTasks;
		Object.keys(timePointTasks).map((key) => {
			if(this.state.activeSteps.includes(key)){
				newTimepointsTasks = {...newTimepointsTasks, [key]: timePointTasks[key]}
			}
		})
		
		return { ...data, timepointTasks: newTimepointsTasks };
	}

	parseFrekvens(data){
		let extra = {}
		const converter = {["-"]: 0, ["Varje vecka"]: 4/1, ["Varannan vecka"]: 4/2, ["Var tredje vecka"]: 4/3, ["Var fjärde vecka"]: 4/4}
		const { extraTasks } = data;
		Object.keys(extraTasks).map((key) => {
			extra = {
				...extra, 
				[key]: {
					...extraTasks[key]	,
					["frequency"]: converter[extraTasks[key].frequency],
				}
			}
		})
		const result = {
			...data, 
			extraTasks: extra,
		}
		return result;
	}

	performCalculation() {

		const data = this.parseFrekvens(this.props.answerModelReducer);

		const result = this.stripNotSelected(data);

	    axios.post(URL + "/inputForm?municipality=" + this.props.role, result, { 
			headers: {
			     'Authorization': 'Bearer ' + this.props.token,
			   }
		})
	    .then((res) => {

	        this.setState({
	        	calculationError: false,
	            hoursPerWeekPerTimepoint: res.data.hoursPerWeekPerTimepoint,
	            hoursPerWeekTimepointTasks: this.createTimeString(res.data.hoursPerWeekTimepointTasks),
	            hoursPerMonthTimepointTasks: this.createTimeString(res.data.hoursPerMonthTimepointTasks),
	            hoursPerDayTimepointTasks: this.createTimeString(res.data.hoursPerDayTimepointTasks),
	            hoursPerMonthExtraTasks: this.createTimeString(res.data.hoursPerMonthExtraTasks),
	            totalHoursPerMonth: this.createTimeString(res.data.totalHoursPerMonth),
	            hoursPerMonthSpecificExtraTasks: res.data.hoursPerMonthSpecificExtraTasks,
	            })
	    })
	    .catch((e) => {
	    	this.setState({ 
	    		showErrorModal: true,
	    		calculationError: true,
	    		hoursPerWeekPerTimepoint: "",
	            hoursPerWeekTimepointTasks: "",
	            hoursPerMonthTimepointTasks: "",
	            hoursPerDayTimepointTasks: "",
	            hoursPerMonthExtraTasks: "",
	            totalHoursPerMonth: "",
	            hoursPerMonthSpecificExtraTasks: "",
	    	})
	        return "Error, no response recieved from server"
	    })
	}

	timeStepCheckboxClicked = (key) => {
	    let i = this.state.activeSteps.indexOf(key) 
	    if(i < 0){
	        this.state.activeSteps.push(key)
	    } else{
	        this.state.activeSteps.splice(i, 1)
	    }

	    // add all timepointtasks in the key
	    //this.addToAnswerModel(key);

	    this.setState({
	        update:!this.state.update
	    })
	}

	addToAnswerModel(key){
	    let answer = {}
	    const { selectedMunicipaly } = this.props.backendDataReducer;
	    const { timepointTasks } = this.props.backendDataReducer.data[selectedMunicipaly];

	    timepointTasks.map((timepointTask, index) => {
	    	answer[timepointTask.name] = {
	    		"frequency": 0,
                "selection": ""
	    	}
	    })
	    this.props.setTimepointTasks(key, answer);
	}

    

	renderCheckBoxes(data){
		return (
			data.map((key) => {
			    return (
			        <FormControlLabel
			            value="end"
			            control={<Checkbox checked={this.state.activeSteps.includes(key)} color="primary" onChange={() => {this.timeStepCheckboxClicked(key)}} />}
			            label={key}
			            labelPlacement="end"
			        />
			    )
			})
		)
	}



	renderStepContent(timepoints, timepointTasks){
    	return (
    		timepoints.map((step, i) => {
    		    if(this.state.activeSteps.indexOf(step) >= 0){
    		        return <FormPage 
    		        			dontShowLevels={false} 
    		        			title={timepoints[i]} 
    		        			values={timepointTasks} 
    		        			client
    		        			error={this.state.calculationError}
    		        		/>
    		    }
    		})
    	)
    }

    renderTimepoints(){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const {showTimepoints} = this.props.backendDataReducer.data[selectedMunicipaly].calculationOutputSettings;
    	if(this.state.hoursPerWeekPerTimepoint && showTimepoints){
    		const timepoints = this.state.hoursPerWeekPerTimepoint;
	    	return Object.keys(timepoints).map((key) => {
	    		return <Typography>Tid för {key} per vecka: {this.createTimeString(timepoints[key])}</Typography>
	    	})
    	}

    }

    renderSpecificExtraPoints(){
    	
    	if(this.state.hoursPerMonthSpecificExtraTasks){
	    	const extraTasks = this.state.hoursPerMonthSpecificExtraTasks;
	    	return Object.keys(extraTasks).map((key) => {
	    		return <Typography>Tid för {key} per månad: {this.createTimeString(extraTasks[key])}</Typography>
	    	})
	    }
    }

    renderSummary(){
    
    	return (
    		<Typography variant="subtitle1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20}}>
    		{this.state.totalHoursPerMonth ? (<Typography><b>Tid per månad: {this.state.totalHoursPerMonth}</b></Typography>) : null } 
    		<br />
    		{this.renderTimepoints()}
    		<br />
    		{this.state.hoursPerMonthTimepointTasks ? (<Typography>Tid för personlig vård per månad: {this.state.hoursPerMonthTimepointTasks}</Typography>) : null } 
    		<br />
    		{this.state.hoursPerWeekTimepointTasks ? (<Typography>Tid för personlig vård per vecka: {this.state.hoursPerWeekTimepointTasks}</Typography>) : null } 
    		<br />
    		{this.state.hoursPerDayTimepointTasks ? (<Typography>Genomsnittlig tid för personlig vård per dag: {this.state.hoursPerDayTimepointTasks}</Typography>) : null } 
    		<br />
    		{this.state.hoursPerMonthExtraTasks ? (<Typography>Tid för övriga insatser per månad: {this.state.hoursPerMonthExtraTasks}</Typography>) : null } 
    		<br />
    		{this.renderSpecificExtraPoints()}
    		<br />
    		</Typography>	
    	)
    }

    showEditTime(){
    	this.setState({
    		editTime: !this.state.editTime,
    	})
    }

    removeSelected(){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const newTimepoints = this.props.backendDataReducer.data[selectedMunicipaly].timepoints.filter((item) => !this.state.activeSteps.includes(item));
    	this.setState({
    		activeSteps: []
    	})
    	this.changeTimepoints(newTimepoints);
    }

    saveTimepoint(text){
    	const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
    	const newTimepoints = this.props.backendDataReducer.data[selectedMunicipaly].timepoints.concat(text);
    	this.changeTimepoints(newTimepoints);
    	this.showEditTime();
    }

    selectMunicipaly(event){

    	this.setState({
    		selectKey: event.target.value
    	})
    	this.props.changeSelectedMunicipaly(event.target.value);
    }


    updateWeeksOff(event){
    	const input = parseInt(event.target.value);
    	if(event.target.value === ""){
    		this.props.updateWeeksOff("")
    	}else if(!Number.isNaN(input)){
    		this.props.updateWeeksOff(parseInt(event.target.value));
    	}
    	
    }

    updateShared(event){

    	this.props.updateShared(event.target.checked);
    }

    createPdf(){
    	const prepped = this.parseFrekvens(this.props.answerModelReducer);
    	const data = this.stripNotSelected(prepped);
    	
    	axios.post(URL + "/inputForm/pdf?municipality=" + this.props.role, data, { responseType: 'blob', headers: {'Authorization': 'Bearer ' + this.props.token}})
    	.then((res) => {
    		var file = new Blob([res.data], {
    			type: 'application/pdf'
    		});
    		saveAs(file, "summary.pdf");


    
    	})
    	.catch((e) => {
    		this.setState({ 
    			showErrorModal: true, 
    			calculationError: true,
    		})
    	    return "Error, no response recieved from server"
    	})
    }

    saveStateToFile(){
    	const prepped = this.parseFrekvens(this.props.answerModelReducer);
    	const data = this.stripNotSelected(prepped);

    	const sendData = {
    		answerReducer: this.props.answerModelReducer,
    		calcFormat: data
    	}
    	var file = new Blob([JSON.stringify(sendData)], {
			type: "text/plain;charset=utf-8"
		});
		saveAs(file, "state.json");
    }

	render() {
		const selectedMunicipaly = this.props.backendDataReducer.selectedMunicipaly;
		const data = this.props.backendDataReducer.data[selectedMunicipaly];
		const logoUrl = this.props.backendDataReducer.logoUrl[selectedMunicipaly];
		let timepoints;
		let timepointTasks;
		let extraTasks;
		let checkboxes;
		if(data){
			timepoints = data.timepoints;
			timepointTasks = data.timepointTasks;
			extraTasks = data.extraTasks;
			checkboxes = data.checkboxes;
		}
		const { municipalies } = this.props.backendDataReducer;
		const { weeksOff, shared} = this.props.answerModelReducer;
		
	  	return (
			<React.Fragment>
			<Modal
			        aria-labelledby="simple-modal-title"
			        aria-describedby="simple-modal-description"
			        open={this.state.showErrorModal}
			        onClose={() => this.setState({ showErrorModal: false })}
			      >	        
						<Paper style={{ 
							marginTop: 200, 
							marginLeft: 'auto', 
							marginRight: 'auto', 
							width: 400, 
							height: 300, 
							zIndex: 2000,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
							paddingLeft: 40,
							paddingRight: 40,
						}}>
							<Typography variant="h6" gutterBottom align="center" style={{paddingTop: 20}}>Ett fel har inträffat, <br />något är inte korrekt ifyllt.</Typography>
							<Button style={{cursor: 'pointer', backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.setState({ showErrorModal: false })}> Stäng </Button>


						</Paper>

				     </Modal>
			{data ? 
				<React.Fragment>
				<CssBaseline />
				<main className={classes.layout}>
					<img src={logoUrl} className="App__logo" />
					<Paper className={classes.paper} style={{ width: 1000}}>
						<Grid container spacing={3}>
						<Grid item xs={12} sm={2}></Grid>
						<Grid item xs={12} sm={8}>	
							<Typography component="h1" variant="h4" align="center" style={{paddingTop: 20}}>Beräkningsstöd för tid i hemtjänsten</Typography>
						</Grid>
						<Grid item xs={12} sm={2} />
							
						
						</Grid>
						<React.Fragment>
						
							<div style={{marginTop:20}}>
								{this.renderCheckBoxes(timepoints)}
							</div>
							<React.Fragment>
								{this.renderStepContent(timepoints, timepointTasks)}
								<FormPage 
									dontShowLevels={false}
									title={"Övriga insatser"}
									values={extraTasks}
									client
									extraTasks
									error={this.state.calculationError}
								/>
							</React.Fragment>		
							
							
						</React.Fragment>
						<Grid container spacing={3}>
						<Grid item xs={12} sm={3}></Grid>
						<Grid item xs={12} sm={6}>
						{data.formSettings.showShared && 
						<div style={{marginTop:30}}>
							<ShareOptionsPage settings={data.formSettings} updateWeeksOff={(event) => this.updateWeeksOff(event)} updateShared={(event) => this.updateShared(event)}/>
						</div>
						}
						<CheckboxPage checkboxes={checkboxes} />
						</Grid>
						<Grid item xs={12} sm={3}></Grid>
						</Grid>
						
						<React.Fragment>
						
							<div style={{marginTop:20, paddingBottom: 20}}>
								<Button variant="outlined" color="primary" onClick={() => this.performCalculation()}>Beräkna</Button>
								<Grid container spacing={3}>
								<Grid item xs={12} sm={4}></Grid>
								<Grid item xs={12} sm={8}>
								{this.renderSummary()}
								</Grid>
								</Grid>
								<Button style={{cursor: 'pointer', backgroundColor: 'white'}} variant="outlined" color="primary" onClick={() => this.createPdf()}> PDF</Button>
								{/*<Button variant="outlined" color="primary" onClick={() => this.saveStateToFile()}>Spara data till utvecklare</Button>*/}
							</div>
					
						</React.Fragment>
					</Paper>
				</main>
				</React.Fragment>
				:
				<div style={{ height: window.innerHeight-60, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					<CircularProgress />
					<Typography variant="h6" gutterBottom align="center" style={{paddingTop: 20, color: 'black'}}>Hämtar data</Typography>
				</div>
			}
			</React.Fragment>
			);
	}
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) =>  ({
    backendDataReducer,
    answerModelReducer
  });

const mapDispatchToProps = (dispatch) => ({
    addAllData: (data) => dispatch(addAllData(data)),
    addInput: (key, input) => dispatch(addInput(key, input)),
    changeTimepoints: (data) => dispatch(changeTimepoints(data)),
    changeSelectedMunicipaly: (name) => dispatch(changeSelectedMunicipaly(name)),
    setTimepointTasks: (time, timePointTasks) => dispatch(setTimepointTasks(time, timePointTasks)),
    setExtraTask: (taskData) => dispatch(setExtraTask(taskData)),
    updateWeeksOff: (number) => dispatch(updateWeeksOff(number)),
    updateShared: (shared) => dispatch(updateShared(shared)),
    setCheckboxes: (checkboxes) => dispatch(setCheckboxes(checkboxes)),
    setAllTimepointTasks: (timepointTasks) => dispatch(setAllTimepointTasks(timepointTasks)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(Client))




